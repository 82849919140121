:root {
  --primary-color: #ff7a45;
  --primary-bg-color: #fff2e8;
  --primary-grey-bg-color: #fafafa;
  --primary-green-color: #52c41a;
  --primary-green-bg-color: #f6ffed;
  --primary-red-color: #ff4d4f;
  --primary-red-bg-color: #fff1f0;
  // 文字
  --primary-font-color: #262626;
  --font-content-color: #595959;
  --font-grey-color: #999999;
}

// 常用色
@primary-color: var(--primary-color);
@primary-bg-color: var(--primary-bg-color);
@primary-grey-bg-color: var(--primary-grey-bg-color);
@primary-green-color: var(--primary-green-color);
@primary-green-bg-color: var(--primary-green-bg-color);
@primary-red-color: var(--primary-red-color);
@primary-red-bg-color: var(--primary-red-bg-color);

// 禁用
@bg-disabled-color: @primary-grey-bg-color;
@border-disabled-color: #d9d9d9;
@font-disabled-color: #cfcfcf;

// 边框
@border-color: #e8e8e8;
@border-width: 1px;
@border-style: solid;
@border: @border-width @border-style @border-color;

// 圆角
@border-radius: 4px;
@border-small-radius: 3px;
@border-middle-radius: 6px;
@border-large-radius: 10px;

// 文字样式
@font-size: 14px;
@font-title-size: 16px;
// 默认颜色
@font-color: var(--primary-font-color);
// 内容颜色
@font-content-color: var(--font-content-color);
// 列表内容颜色
@font-grey-color: var(--font-grey-color);

// 按钮
@button-small-height: 32px;
@button-middle-height: 36px;
@button-large-height: 38px;

@button-small-padding: 9px;
@button-middle-padding: 14px;
@button-large-padding: 14px;

@button-radius: @border-radius;

// 分割线
@divider-color: #e8e8e8;

// 阴影样式
@box-shadow: 0px 9px 28px 1px rgba(0, 0, 0, 0.05);
