@import '@/assets/styles/variables.less';

// 在这里可以修改antd的全局默认样式
#root {
  height: 100%;
}

.ant-layout {
  background-color: #f6f6f6;
}

// 文本超出 2 行
.ellipse__2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.table-cell {
  box-sizing: border-box;
}

.ant-pro-basicLayout-content {
  margin: 20px 24px;
}

// 按钮
.ant-btn {
  padding: 0 @button-middle-padding;
  height: @button-middle-height;
}
.ant-btn-sm {
  padding: 0 @button-small-padding;
  height: @button-small-height;
}

.ant-btn-lg {
  padding: 0 @button-large-padding;
  height: @button-large-height;
}
// modal
.ant-modal-header {
  border-radius: 12px 12px 0 0;
  padding: 13px 24px;
  background-color: #fff !important;
  .ant-modal-title {
    color: @font-color;
  }
}
.ant-modal-content {
  border-radius: 12px;
}
.ant-modal-body {
  .ant-input,
  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-disabled,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker,
  .ant-input-number {
    border-radius: 4px;
  }
  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact
    > .ant-select:first-child
    > .ant-select-selector,
  .ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete:first-child
    .ant-input,
  .ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:first-child
    .ant-input {
    border-radius: 4px 0 0 4px;
  }
}
.ant-modal-footer {
  border-top-color: #e9e9e9;
  .ant-btn {
    height: @button-small-height;
    padding: 0 @button-small-padding;
  }
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  top: 50%;
  left: 0;
  right: 100%;
  height: 16px;
  transform: translateY(-50%);
}

.ant-menu-submenu {
  .ant-menu-inline {
    .ant-menu-item-only-child {
      .ant-menu-title-content {
        padding-left: 12px;
        font-size: 14px;
        font-weight: 400;
        color: #757575;
      }
    }
    .ant-menu-item-selected .ant-menu-title-content {
      color: @primary-color;
    }
  }
}

.ant-pro-menu-item {
  display: flex;
  align-items: center;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 38px;
  line-height: 38px;
}

.disAbleInputClass {
  color: @font-content-color !important;
}
.disableAdmin {
  border: none;
  outline: none;
  background-color: #fff !important;
  color: @font-color !important;
}
.ant-picker.ant-picker-range.ant-picker-large.ant-picker-disabled {
  background-color: #fafafa;
}

// 输入框
.ant-input,
.ant-select .ant-select-selector,
.ant-input-affix-wrapper,
.ant-btn,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select,
.ant-picker {
  border-radius: 4px;
}

.ant-input,
.ant-select .ant-select-selector,
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select,
.ant-picker {
  font-size: 14px;
  color: #262626;
}

// 输入框
.ant-picker {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.ant-input-affix-wrapper-lg,
.ant-picker-large {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.ant-input-affix-wrapper-sm,
.ant-picker-small {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

// 选择框
.ant-select .ant-select-selector {
  min-height: @button-middle-height !important;
}

.ant-select-lg .ant-select-selector {
  min-height: @button-large-height !important;
}

.ant-select-sm .ant-select-selector {
  min-height: @button-small-height !important;
}

.ant-select-dropdown {
  // 选择框
  .ant-select-item.ant-select-item-option-selected,
  .ant-cascader-menu-item.ant-cascader-menu-item-active {
    color: @primary-color;
    background-color: transparent !important;
  }
  .ant-select-item.ant-select-item-option-active {
    background-color: #f5f5f5 !important;
  }
  // 级联框
  .ant-cascader-menu {
    .ant-cascader-menu-item:hover {
      background-color: #f5f5f5 !important;
    }
    .ant-cascader-menu-item[aria-checked='true']
      .ant-cascader-menu-item-content {
      color: @primary-color !important;
    }
  }
}

//
.ant-input-show-count-suffix {
  font-size: 14px;
  color: #8c8c8c;
  font-weight: 400;
}

.ant-btn-default,
.ant-btn-ghost {
  color: @font-content-color;
}

.ant-switch[aria-checked='false'] {
  background-color: #bfbfbf;
}

.addrProcess {
  .ant-progress-inner {
    background: #f0f0f0 !important;
  }
  .ant-progress-bg {
    height: 16px !important;
  }
}

.ant-form.ant-form-horizontal.createAddressingTaskForm {
  .ant-form-item-control {
    margin-left: 12px !important;
  }
  .ant-select-selection-placeholder {
    font-size: 14px !important;
  }
}

// 分页器边距
.ant-table-pagination.ant-pagination {
  margin: 20px 0 12px 0;
}

// 单选样式
.ant-radio-wrapper {
  color: @font-content-color;
}

.ant-radio-wrapper-checked {
  color: @primary-color;
}

.ant-pro-table-toolbar {
  height: auto !important;
  margin-bottom: 18px;
}
.ant-pro-table-column-setting-overlay .ant-popover-inner-content {
  padding: 12px 16px !important;
}
.ant-pro-table-column-setting-list-item
  .ant-pro-table-column-setting-list-item-option {
  display: block;
  width: 36px;
  > span > span.anticon {
    display: none;
  }
}
.ant-pro-table-column-setting-list-item:hover
  .ant-pro-table-column-setting-list-item-option
  > span
  > span.anticon {
  display: inline-block;
}

// 排序表格居中
.ant-table-content .ant-table-column-sorters-center {
  .ant-table-column-sorters {
    justify-content: center;
    .ant-table-column-title {
      flex: none;
    }
  }
}

.ant-table-content .ant-table-column-sort {
  .ant-table-column-title {
    color: @primary-color !important;
  }
}
.ant-table-content td.ant-table-column-sort {
  background: none;
  color: @primary-color !important;
}
// 多选项
.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: @border-radius;
  }
}
.ant-checkbox-wrapper {
  width: auto !important;
}

// 表单样式
.ant-form-item-label.ant-col-24 {
  margin-bottom: 0;
  padding-bottom: 0;
}

.ant-form-item {
  margin-bottom: 14px;
}

.ant-breadcrumb {
  line-height: 14px;
}

// 表格分页样式
.ant-pagination-total-text {
  margin-right: 16px;
}
.ant-pagination-options {
  margin-left: 12px;
}
.ant-pagination-options-quick-jumper {
  margin-left: 12px;
}
.ant-pagination .ant-pagination-options-quick-jumper {
  height: 32px;
  input {
    height: 30px;
  }
}

// 滚轮样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

// 菜单
.ant-cascader-menu {
  min-width: 150px;
  overflow: hidden;
}

// 表格
.ant-tabs-tab .ant-tabs-tab-btn {
  color: @font-content-color;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @primary-color;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3px;
}

.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  align-items: flex-start;
}

.ant-table-thead {
  .ant-table-cell {
    white-space: nowrap;
  }
}

.ant-table-thead > tr > th {
  border-bottom: none;
  background: #f6f6f6;
  color: @font-color !important;
  font-weight: bolder;
}

.ant-table-tbody > tr > td {
  border-bottom-color: @border-color;
  color: @font-content-color !important;
}

.ant-table-container {
  table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  table > thead > tr:last-child th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .ant-divider-vertical {
    border-color: #e9e9e9;
  }
}

.ant-input,
.ant-select .ant-select-selector,
.ant-input-affix-wrapper,
.ant-btn,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select,
.ant-picker {
  border-radius: @border-radius;
}

.ant-pagination .ant-select .ant-select-selector {
  border-radius: @border-radius;
}

.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:first-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact
  > .ant-select-auto-complete:first-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:first-child
  .ant-input {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact
  > .ant-select:last-child
  > .ant-select-selector,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker:last-child
  .ant-input,
.ant-input-group.ant-input-group-compact
  > .ant-cascader-picker-focused:last-child
  .ant-input {
  border-top-right-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: @border-radius;
  border-bottom-left-radius: @border-radius;
}

.ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

// 确认提示框
.ant-popover {
  .ant-popover-inner-content {
    .ant-popover-message-title {
      color: @font-color;
    }
    .ant-popconfirm-description {
      color: @font-grey-color;
    }
  }
}

// 分页器
.ant-pagination {
  .ant-pagination-item {
    border-radius: @border-radius;
    a {
      color: @font-content-color;
    }
  }
  .ant-select {
    .ant-select-selector {
      min-height: @button-small-height !important;
    }
  }
  .ant-pagination-total-text,
  .ant-pagination-options-quick-jumper {
    line-height: @button-small-height;
    color: @font-content-color;
  }
  .ant-pagination-item-active {
    background-color: @primary-color;
    border-color: @primary-color;
    a {
      color: #fff;
    }
  }
  .ant-pagination-options-quick-jumper {
    input {
      height: 32px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 32px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 30px;
  }
}
.ant-pagination-options-quick-jumper {
  line-height: 32px;
}

// 禁用
.ant-select.ant-select-disabled {
  .ant-select-selection-item {
    * {
      color: @font-disabled-color !important;
    }
  }
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
  * {
    color: @font-disabled-color !important;
  }
}

@primary-color: #FF7A45;@success-color: #15C349;@warning-color: #FF9B3A;@error-color: #FF4646;@font-size-base: 14px;@disabled-color: #BBC1CC;@border-radius-base: 2px;@border-color-base: #D9D9D9;@height-base: 36px;@height-sm: 32px;@input-height-lg: @height-base;@input-height-sm: @height-base;@input-placeholder-color: rgba(0, 0, 0, 0.25);@input-color: rgba(0, 0, 0, 0.85);@input-disabled-bg: #FAFAFA;@picker-border-color: @border-color-base;@tabs-active-color: @primary-color;@tabs-card-tab-active-border-top: 3px solid transparent;@tabs-title-font-size: 16px;@tabs-card-active-color: @primary-color;@btn-font-size-sm: @font-size-base;@pagination-item-size: 32px;@select-single-item-height-lg: 36px;@modal-header-bg: #FAFAFA;@modal-body-padding: 30px 36px;@modal-header-padding-horizontal: 24px;@modal-footer-padding-horizontal: 24px;@modal-footer-padding-vertical: 12px;@tooltip-max-width: 350px;@menu-item-active-bg: #F6F6F6;@menu-item-height: 48px;@menu-inline-toplevel-item-height: 48px;@menu-horizontal-line-height: 48px;@table-padding-horizontal: 24px;@menu-icon-size: 16px;@menu-icon-margin-right: 12px;@menu-item-padding-horizontal: 24px;@table-padding-vertical: 14px;