@import '@/assets/styles/variables.less';

body {
  font-family: Helvetica, Arial, 'PingFangSC-Regular', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', 'Microsoft JhengHei';
  color: @font-color;
}

p,
video,
img {
  margin: 0;
}

textarea {
  font-weight: 400 !important;
}

// common container 等相关公共样式
.top-slot {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  > div:nth-last-child(2) {
    margin-right: 16px;
  }

  .top-search-item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    min-height: 36px;
    margin-right: 30px;
    margin-bottom: 12px;
    .search-text {
      display: flex;
      margin-right: 12px;
      font-size: 14px;
      font-weight: 400;
      flex-wrap: nowrap;
    }

    .input-search-icon {
      width: 14px;
      height: 14px;
    }
  }
}

.btns {
  button {
    margin-right: 8px;
  }
}

.flex-full {
  flex: 1;
  min-width: 0px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type='number'] {
  -moz-appearance: textfield;
}

@primary-color: #FF7A45;@success-color: #15C349;@warning-color: #FF9B3A;@error-color: #FF4646;@font-size-base: 14px;@disabled-color: #BBC1CC;@border-radius-base: 2px;@border-color-base: #D9D9D9;@height-base: 36px;@height-sm: 32px;@input-height-lg: @height-base;@input-height-sm: @height-base;@input-placeholder-color: rgba(0, 0, 0, 0.25);@input-color: rgba(0, 0, 0, 0.85);@input-disabled-bg: #FAFAFA;@picker-border-color: @border-color-base;@tabs-active-color: @primary-color;@tabs-card-tab-active-border-top: 3px solid transparent;@tabs-title-font-size: 16px;@tabs-card-active-color: @primary-color;@btn-font-size-sm: @font-size-base;@pagination-item-size: 32px;@select-single-item-height-lg: 36px;@modal-header-bg: #FAFAFA;@modal-body-padding: 30px 36px;@modal-header-padding-horizontal: 24px;@modal-footer-padding-horizontal: 24px;@modal-footer-padding-vertical: 12px;@tooltip-max-width: 350px;@menu-item-active-bg: #F6F6F6;@menu-item-height: 48px;@menu-inline-toplevel-item-height: 48px;@menu-horizontal-line-height: 48px;@table-padding-horizontal: 24px;@menu-icon-size: 16px;@menu-icon-margin-right: 12px;@menu-item-padding-horizontal: 24px;@table-padding-vertical: 14px;