
            @import '@/assets/styles/variables.less';
            @import '@/assets/styles/mixins.less';
          
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 100%;
}

.formWrap {
  margin-top: -15%;
  .titleWrap {
    margin-bottom: 30px;
    .title {
      font-weight: 500;
      color: #000000;
      font-size: 30px;
      margin-right: 16px;
    }
    .subtitle {
      font-size: 21px;
      color: #666666;
    }
  }
  .formContent {
    padding: 40px 47px 58px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 1px rgba(197, 197, 197, 0.5),
      0px 2px 35px 0px rgba(244, 244, 244, 0.5),
      0px 2px 15px 0px rgba(236, 236, 236, 0.5);
    border-radius: 12px;
    position: relative;
    width: 420px;
    height: 480px;

    .userName {
      padding: 20px 0 20px;
    }

    .verificationCodeBtn {
      border: none;
    }
    .fullWidth {
      width: 100%;
      margin: 43px 0 34px;
      border-radius: 30px;
    }
    .itemPrefixIcon {
      margin-right: 8px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
}
