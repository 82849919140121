// 单行文本溢出
.singleLineTextOverflow(@maxWidth: 100%) {
  width: auto;
  max-width: @maxWidth;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 多行文本溢出
.multipleLinesOfTextOverflow(@line: 2, @height: 60px) {
  width: 100%;
  height: @height;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}

// 强制换行
.lineWrap {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

.chartsBoxMixins {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: @border-small-radius;
  position: relative;
  .chart {
    width: 100%;
    height: 400px;
    transform-origin: 0 0;
  }
  .empty {
    position: absolute;
    background: #ffffff;
    width: 156px;
    height: 114px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 1px 16px 0px #e2e2e2,
      0px 0px 1px 0px rgba(218, 218, 218, 0.5),
      0px 2px 18px 0px rgba(237, 237, 237, 0.5);
    border-radius: 4px;
    border: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      height: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #9a9a9a;
      line-height: 14px;
      margin-top: 10px;
      white-space: nowrap;
      text-align: center;
    }
  }
}
