
            @import '@/assets/styles/variables.less';
            @import '@/assets/styles/mixins.less';
          
.main {
  height: calc(100vh / var(--zoom));
  display: flex;
  width: 100vw;
  height: 100vh;
}

.leftWrap {
  position: relative;
  height: 100%;
  .logo {
    position: absolute;
    z-index: 10;
    height: 25px;
    top: 20px;
    left: 42px;
  }
  .aside {
    position: relative;
    height: 100%;
    .loginImg {
      height: 100%;
    }
    .describeWrap {
      position: absolute;
      top: 24%;
      width: 100%;
      text-align: center;
      .title {
        font-size: 48px;
        font-weight: 500;
        color: #111111;
      }
      .subtitle {
        font-size: 28px;
        color: #6b6c6c;
        margin-top: 5px;
        padding: 0 30px;
      }
    }
  }
}

.rightWrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  .titleWrap {
    margin-top: 24px;
    margin-right: 76px;
    height: 35px;
    display: flex;
    justify-content: flex-end;
    .linkWrap {
      display: flex;
      align-items: center;
      margin: 0 20px;
      .backImg {
        margin-right: 8px;
        width: 16px;
      }
      .text {
        color: #1e1e1e;
        font-size: 16px;
        cursor: pointer;
      }
      .icon {
        margin-left: 3px;
        color: #aaa;
        font-size: 16px;
      }
      &:hover {
        .text,
        .icon {
          color: @primary-color;
        }
      }
    }
  }
  .childrenWrap {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.menu {
  padding: 0;
  :global(.ant-dropdown-menu-item) {
    padding: 10px 20px;
  }
}
