
            @import '@/assets/styles/variables.less';
            @import '@/assets/styles/mixins.less';
          
.avatarBox {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .username {
    margin-left: 12px;
  }
}
.menuItemStyle {
  position: relative;
  &::before {
    content: '';
    width: 3px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-left-color: @primary-color;
  }
}

.logo {
  margin-left: 18px;
  height: 22px;
  transform-origin: 0% 50%;
  transition: all 0.2s;
  &.isOpenLogo {
    margin-left: 0;
    transform: scale(0.25);
  }
}
