
            @import '@/assets/styles/variables.less';
            @import '@/assets/styles/mixins.less';
          
.container {
  background-color: #fff;
  min-width: 1212px;

  .header {
    margin: 0 auto;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .logo {
      margin-left: 42px;
      align-self: center;
      height: 25px;
      cursor: pointer;
    }
    .btns {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      .switchLanguage {
        display: flex;
        align-items: center;
        .text {
          color: #1e1e1e;
          font-size: 16px;
          cursor: pointer;
        }
        .icon {
          margin-left: 3px;
          color: #aaa;
          font-size: 16px;
        }
        &:hover {
          .text,
          .icon {
            color: @primary-color;
          }
        }
      }
      .logUser {
        color: #1e1e1e;
        height: 64px;
        line-height: 64px;
        margin-right: 42px;
      }
      .signinBtn {
        color: #1e1e1e;
        width: 28px;
        cursor: pointer;
        margin-left: 10px;
      }
      .signupBtn {
        width: 104px;
        display: inline-block;
        margin-left: 30px;
        color: #fff;
        height: 64px;
        line-height: 64px;
        background-color: #fc7d01;
        cursor: pointer;
      }
    }
  }

  // banner
  .banner {
    width: 100%;
    height: 440px;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      border-top: 1px solid #ebebeb;
    }
    .bannerCtx {
      display: block;
      width: 80%;
      height: 100%;
      margin: 0 auto;
      padding: 90px 8%;
      box-sizing: border-box;

      .bannerTitle {
        height: 42px;
        line-height: 42px;
        font-size: 42px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 24px;
      }
      .bannerDesc {
        height: 16px;
        font-size: 16px;
        font-weight: normal;
        color: #333333;
        line-height: 16px;
      }
      .bannerBtns {
        margin-top: 42px;
        font-size: 16px;
        font-weight: 400;
        line-height: 44px;
        .trynow,
        .joinnow {
          display: inline-block;
          padding: 6px 20px;
          text-align: center;
          cursor: pointer;
        }
        .trynow {
          background-color: #fc7d01;
          color: #ffffff;
          margin-right: 12px;
        }
        .joinnow {
          border: 1px solid #fc7d01;
          color: #fc7d01;
        }
      }
    }
  }

  .main {
    width: 100%;
    overflow: hidden;
    margin: 0 auto;

    // contract section
    .contract {
      display: block;
      max-width: 1212px;
      margin: 70px auto;
      .contractContent {
        display: flex;
        flex-direction: row;
        padding: 32px 0;
        .contractImg {
          width: 504px;
          height: 507px;
        }
        .contractInfo {
          margin-left: 100px;
          margin-top: 128px;
          .infoTitle {
            height: 26px;
            font-size: 26px;
            font-weight: 500;
            color: #333333;
            line-height: 26px;
            margin-bottom: 0;
            .infoIcon {
              width: 26px;
              height: 26px;
            }
          }
          .infoList {
            padding-top: 12px;
            padding-left: 36px;
            list-style: none;
            .infoItem {
              margin: 24px 0;
              height: 20px;
              line-height: 20px;
              font-size: 20px;
              font-weight: 400;
              color: #333333;
              position: relative;
            }
            li.infoItem::before {
              content: '';
              width: 8px;
              height: 8px;
              position: absolute;
              left: -20px;
              top: 50%;
              transform: translateY(-50%);
              background-color: #fc7d01;
            }
          }
        }
      }
    }

    // plus score section
    .plusPoints {
      padding: 64px 0;
      background-color: #f6f6f5;
      .ppWrappwer {
        width: 100%;
        max-width: 1212px;
        margin: 0 auto;
        .ppList {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: space-around;
          .ppCard {
            width: 380px;
            height: 317px;
            background: #ffffff;
            box-shadow: 0px 9px 20px 0px rgba(222, 222, 222, 0.65);
            border-radius: 24px;
            margin: 18px 0;
            text-align: center;
            padding: 49px 22px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:last-child {
              cursor: pointer;
            }
            .ppCardImg {
              width: 50px;
              height: 50px;
            }
            .ppCardTitle {
              font-size: 22px;
              font-weight: 500;
              color: #000000;
              line-height: 28px;
              margin: 32px 0 24px;
              white-space: nowrap;
            }
            .ppCardDesc {
              flex-shrink: 0;
              display: inline-block;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 32px;
              text-align: left;
              .multipleLinesOfTextOverflow(3);
              height: 100px;
            }
          }
        }
      }
    }

    // advance section
    .advanced {
      width: 80%;
      margin: 0 auto;
      padding: 64px 0;
      text-align: center;
      .advancedContent {
        position: relative;
        background-repeat: no-repeat;
        background-size: 420px 420px;
        background-position: center center;
        .advCenter {
          padding-top: 64px;
          padding-bottom: 18px;
          text-align: center;
          position: relative;
          .advCircle {
            width: 420px;
            height: 420px;
            margin-bottom: 78px;
          }
          .contactus {
            display: inline-block;
            width: 150px;
            height: 48px;
            background: #fc7d01;
            border-radius: 24px;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            line-height: 48px;
            cursor: pointer;
          }
        }
        .advList {
          margin-top: 52px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          .advItem {
            text-align: center;
            flex: 50%;
            &:nth-last-child(2),
            &:nth-last-child(1) {
              margin-top: 42px;
            }
            .advItemContent {
              width: 340px;
              .advImg {
                width: 60px;
                height: 60px;
              }
              .advTitle {
                height: 28px;
                font-size: 20px;
                font-weight: 500;
                color: #000000;
                line-height: 28px;
                margin-bottom: 24px;
                margin-top: 20px;
              }
              .advDesc {
                font-size: 18px;
                font-weight: 500;
                color: #333333;
                text-align: left;
                padding-left: 28px;
                .advLi {
                  list-style-type: square;
                  line-height: 30px;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    height: 420px;
    margin: 0 auto;
    position: relative;
    background-color: #181818;
    .footerImg {
      width: 100%;
      height: 181px;
      object-fit: cover;
    }
    .footerContainer {
      display: block;
      width: 100%;
      overflow: hidden;
      padding: 43px 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 50px;

      .ftContent {
        height: 18px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: 18px;
        margin: 0;
      }
      .ftBtn {
        display: inline-block;
        padding: 4px 12px;
        line-height: 42px;
        text-align: center;
        background: #fc7d01;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 27px;
        cursor: pointer;
      }
    }
    // 公网安备
    .footerExtra {
      background-color: #252525;
      height: 240px;
      padding: 70px 0;
      .footerExtraContent {
        height: 100px;
      }
      .copyright {
        padding: 15px 0;
        text-align: center;
        color: #939393;
        color: #fff;
        a {
          color: #fff;
        }
      }
      .recordCode {
        margin: 0 auto;
        padding-bottom: 15px;
        text-align: center;
        img {
          float: left;
        }
        a {
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
          vertical-align: middle;
        }
        p {
          float: left;
          height: 20px;
          line-height: 20px;
          margin: 0px 0px 0px 5px;
          color: #fff;
        }
      }
    }
  }
}
.sectionTitle {
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 24px;
}

.menu {
  padding: 0;
  :global(.ant-dropdown-menu-item) {
    padding: 10px 20px;
  }
}
